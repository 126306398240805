<template>
  <ModalComponent>
    <div class="d-flex modal__header">
      <h4>
        Открыть доступ
      </h4>
      <div @click="() => $emit('close')" class="modal__close">
        <CloseIcon />
      </div>
    </div>
    <div class="modal__content">
      <div class="flex-1">
        <CustomInput v-model="code" label="Введите ключ доступа"/>
      </div>
      <button :class="{ disabled: !code}" class="btn-prime mt-20" @click="() => checkCodeHandler()">Проверить ключ</button>
    </div>
    <div class="modal__content">
      <div class="mt-16">
        Если у вас нет ключа доступа, то его можно
        <button class="btn-prime" @click="() => $root.openMessageModal()">КУПИТЬ</button>
      </div>
    </div>
  </ModalComponent>
</template>

<script>
import { mapMutations } from 'vuex'
import ModalComponent from "@/components/parts/Modal";
import CloseIcon from "@/components/icons/Close";
import CustomInput from "@/components/ui/CustomInput";

export default {
  name: "OpenTaskModal",
  components: {
    ModalComponent,
    CloseIcon,
    CustomInput
  },
  props: {
    params: Object
  },
  data () {
    return {
      code: ''
    }
  },
  methods: {
    ...mapMutations('ege', [
      'checkCode'
    ]),
    click (btn) {
      if (btn.click) {
        this.clickHandler(btn.click)
      }

      if (btn.afterClick) {
        this.clickHandler(btn.afterClick)
      }
    },
    clickHandler (click) {
      if (typeof click === 'string') {
        if (click === 'emitClose') {
          return this.$emit('close')
        }
      }

      return click()
    },
    checkCodeHandler () {
      if (!this.code) {
        alert('Введите ключ')
        return
      }
      this.checkCode(this.code)
    }
  }
}
</script>

<style lang="less">
.modal__close {
  cursor: pointer;
}
.modal__header {
  justify-content: space-between;
  padding: 12px;
  min-width: 480px;
}

.modal__content {
  padding: 0 12px 12px;
  display: flex;
  gap: 12px;
}
</style>
