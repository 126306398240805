<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :fill="color" d="M13.0729 0H6.9375C1.33331 0 0 1.33331 0 6.92706V13.0625C0 18.6666 1.32294 20 6.92706 20H13.0625C18.6667 20 20 18.6771 20 13.0729V6.9375C20 1.33331 18.6771 0 13.0729 0ZM16.1458 14.2708H14.6875C14.1354 14.2708 13.9687 13.8229 12.9792 12.8333C12.1146 12 11.75 11.8958 11.5312 11.8958C11.2292 11.8958 11.1458 11.9791 11.1458 12.3958V13.7083C11.1458 14.0625 11.0312 14.2708 10.1042 14.2708C9.20458 14.2104 8.33219 13.9371 7.55888 13.4735C6.78557 13.0098 6.13346 12.3691 5.65625 11.6041C4.52336 10.194 3.73511 8.53906 3.35419 6.77081C3.35419 6.55206 3.4375 6.35413 3.85419 6.35413H5.3125C5.6875 6.35413 5.82294 6.52081 5.96875 6.90625C6.67706 8.98956 7.88544 10.8021 8.375 10.8021C8.5625 10.8021 8.64581 10.7188 8.64581 10.25V8.10413C8.58331 7.125 8.0625 7.04163 8.0625 6.6875C8.06936 6.59407 8.11236 6.50699 8.18237 6.44475C8.25239 6.3825 8.34391 6.35 8.4375 6.35413H10.7292C11.0417 6.35413 11.1458 6.51038 11.1458 6.88538V9.78125C11.1458 10.0938 11.2812 10.1979 11.375 10.1979C11.5625 10.1979 11.7083 10.0938 12.0521 9.75C12.7907 8.84918 13.3943 7.84559 13.8438 6.77081C13.8896 6.64149 13.9766 6.53074 14.0913 6.4555C14.2061 6.38025 14.3423 6.34465 14.4792 6.35413H15.9375C16.375 6.35413 16.4688 6.57288 16.375 6.88538C15.8444 8.07369 15.1878 9.20168 14.4167 10.25C14.2604 10.4896 14.1979 10.6146 14.4167 10.8958C14.5625 11.1146 15.0729 11.5416 15.4167 11.9479C15.9167 12.4466 16.3318 13.0237 16.6458 13.6562C16.7708 14.0625 16.5625 14.2708 16.1458 14.2708Z" />
  </svg>
</template>

<script>
export default {
  name: "VkIcon",
  props: {
    color: {
      default: 'black',
      type: String
    }
  }
}
</script>

<style scoped>

</style>
