const CODES = {
  tasks: {
    t1: '9w1su2c7oqf3573y5gm1uwnzheoc8lrh1691190860053',
    t2: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t3: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t4: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t5: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t6: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t7: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t8: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t9: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t10: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t11: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t12: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t13: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t14: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t15: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t16: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t17: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t18: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t19: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t20: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t21: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t22: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t23: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t24: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t25: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t26: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t27: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
  },
  theory: {
    t1: '9w1su2c7oqf3573y5gm1uwnzheoc8lrh1691190860053',
    t2: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t3: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t4: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t5: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t6: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t7: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t8: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t9: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t10: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t11: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t12: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t13: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t14: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t15: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t16: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t17: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t18: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t19: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t20: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t21: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t22: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t23: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t24: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t25: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t26: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
    t27: '9w1su2c7oqf3573y5gm1uwnzhe9c8lrh1691190860053',
  },
  special: {
    all: 'yntwqrew2xnq5299fjwy854eiud34342490910817'
  }
}

const KEYS_ACCESS = {
  'kod1': CODES.tasks.t1,
  'kod2': CODES.tasks.t2,
  'all': CODES.special.all
}

const KEYS_ACCESS_ARRAY = Object.keys(KEYS_ACCESS)

const LS_CODE = 'yntwqrew2xnq5299fjwy854eiud'
const PK_CODE = 'yntwqrew22xnq599fj4wy854eud'

export default {
  namespaced: true,
  state: {
    codes: CODES,
    keys: KEYS_ACCESS,
    activated: localStorage.getItem(LS_CODE) && JSON.parse(localStorage.getItem(LS_CODE)) || {
      [CODES.tasks.t1]: true
    },
    dones: localStorage.getItem(PK_CODE) && JSON.parse(localStorage.getItem(PK_CODE)) || []
  },
  getters: {
    getCodes: (state) => state.codes,
    getKeys: (state) => state.keys,

    getActivated: (state) => state.activated,
    getActivatedByKey: (state) => key => state.activated[key],

    getActivatedSpecialAll: (state) => state.activated[CODES.special.all],

    getDone: (state) => (key) => state.dones.includes(key)
  },
  mutations: {
    checkCode: (state, payload) => {
      const index = KEYS_ACCESS_ARRAY.indexOf(payload)

      if (index >= 0) {
        const key = KEYS_ACCESS_ARRAY[index]
        const code = state.keys[key]
        state.activated[code] = true
        state.activated = { ...state.activated }
        localStorage.setItem(LS_CODE, JSON.stringify(state.activated))
      } else {
        alert('Ключ неверный! Попробуйте другой.')
      }
    },
    setDone: (state, key) => {
      const index = state.dones.indexOf(key)
      if (index >= 0) {
        state.dones.splice(index, 1)
      } else {
        state.dones.push(key)
      }
      localStorage.setItem(PK_CODE, JSON.stringify(state.dones))
    }
  }
}
