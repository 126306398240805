<template>
  <PageLayout title="ОБ АВТОРЕ">
    <section class="p-16 d-flex" style="gap: 20px">
      <div class="flex-1">
        <h4>
          Резников Константин Георгиевич
        </h4>
        <h5>
          Fullstack-разработчик, программист, IT-специалист.
        </h5>
        <p class="fs-12">
          Главный специалист по разработке в одном из ведущих банков страны. <br>
          Преподаватель ВУЗа по разработке и программированию. <br>
          Частный репетитор по информатике и программированию для подготовки к ЕГЭ, ОГЭ, школьной программы и студентов начальных курсов. <br>
          Разработчик методических пособий для ВУЗов и методик обучения. <br>
        </p>
        <h5>
          Учебная и научная деятельность
        </h5>
        <p class="fs-12">
          В 2015 году закончил 11 классов в обычной школе Тамбовской области. Сдав ЕГЭ, поступил на бюджет в Воронежский государственный университет, где окончил бакалавриат в 2019 году и тогда же продолжил обучение в магистратуре. В 2021 году защитил магистерскую диссертацию по теме “Разработка веб-приложения для визуализации трехмерных поверхностей”. С 2021 года является преподавателем в ВУЗе и продолжает обучение в аспирантуре. Ведет научную деятельность в области разработки и готовит кандидатскую диссертацию. Частные занятия и репетиторскую деятельность осуществляет с 2016 года, начав преподавать еще будучи студентом бакалавриата.
        </p>
        <h5>
          Разработка и программирование
        </h5>
        <p class="fs-12">
          Программированием начал увлекаться еще в школе, а с компьютером и техникой “на ты” совсем с раннего детства. Профессионально начал заниматься разработкой с 2016 года. Тогда еще просто разрабатывая небольшие решения и проекты, которые уже выходили за рамки обычных лабораторных работ в университете. С 2017 года занимается коммерческой разработкой в масштабных проектах, сотрудничая с крупными заказчиками, в том числе - иностранными.
        </p>
      </div>
      <div>
        <div class="ava__container">
          <img class="ava" src="@/assets/ava.jpeg" alt="Фото">
        </div>
        <div class="text-center pathes">
          Бакалавриат (2015-2019) <br>
          Магистратура (2019-2021) <br>
          Аспирантура (2021-н.в.) <br>
          <br>
          Преподавание (2021-н.в.) <br>
          Репетиторство (2016-н.в.) <br>
          <br>
          Fullstack-разработчик (2017-н.в.)
        </div>
      </div>
    </section>
  </PageLayout>
</template>

<script>
import PageLayout from '../parts/PageLayout'

export default {
  name: 'AboutPage',
  components: {
    PageLayout
  }
}
</script>

<style lang="less">
.ava {
  width: 100%;

  &__container {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    box-sizing: border-box;
    margin-bottom: 36px;
  }
}
.pathes {
  font-size: 10px;
  font-weight: 700;
}
</style>
