<template>
  <PageLayout title="ЕГЭ Информатика">
    <section class="p-16">
      <Component v-if="task" :is="task" />
      <div v-else>
        <TaskContainer />
      </div>
    </section>

    <template slot="sidebar">
      <MenuEge />
    </template>
  </PageLayout>
</template>

<script>
import PageLayout from '../parts/PageLayout'
import { helpModal } from "@/mixins/modals";
import MenuEge from "@/components/parts/MenuEge";
import TaskContainer from "@/components/parts/TaskContainer";

const getTask = (id) => import('../tasks/' + id)

export default {
  name: 'TaskPage',
  mixins: [helpModal],
  components: {
    PageLayout,
    MenuEge,
    TaskContainer
  },
  data () {
    return {
      task: null
    }
  },
  computed: {
    taskNumber () {
      return this.$route.params.id
    }
  },
  mounted () {
    this.loadTask()
  },
  watch: {
    taskNumber: {
      handler () {
        this.loadTask()
      }
    }
  },
  methods: {
    loadTask () {
      getTask(this.taskNumber)
        .then((v) => {
          this.task = v.default
        })
        .catch(() => {
          this.task = null
        })
    }
  }
}
</script>

<style lang="less">
.grid {
  display: grid;
  grid-gap: 24px;

  &--main {
    grid-template-columns: 1fr 240px;
  }
}

.contacts {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}
</style>
