<template>
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :fill="color" d="M13 7V5C13 2.2 10.8 0 8 0C5.2 0 3 2.2 3 5V7C1.3 7 0 8.3 0 10V17C0 18.7 1.3 20 3 20H13C14.7 20 16 18.7 16 17V10C16 8.3 14.7 7 13 7ZM5 5C5 3.3 6.3 2 8 2C9.7 2 11 3.3 11 5V7H5V5ZM9 15C9 15.6 8.6 16 8 16C7.4 16 7 15.6 7 15V12C7 11.4 7.4 11 8 11C8.6 11 9 11.4 9 12V15Z"/>
  </svg>
</template>

<script>
export default {
  name: "LockIcon",
  props: {
    color: {
      default: 'black',
      type: String
    }
  }
}
</script>

<style scoped>

</style>
