import { mapMutations } from "vuex";
import HelpModal from "@/components/modals/HelpModal";
import OpenTaskModal from "@/components/modals/OpenTaskModal";
import MessageModal from "@/components/modals/MessageModal";
import RepModal from "@/components/modals/RepModal";

export const helpModal = {
  methods: {
    ...mapMutations('modals', [
      'openModal'
    ]),
    openHelpModal (params = {}) {
      this.openModal({
        component: HelpModal,
        params
      })
    },
    openOpenTaskModal (params = {}) {
      this.openModal({
        component: OpenTaskModal,
        params
      })
    },
    openMessageModal (params = {}) {
      this.openModal({
        component: MessageModal,
        params
      })
    },
    openRepModal (params = {}) {
      this.openModal({
        component: RepModal,
        params
      })
    }
  }
}

