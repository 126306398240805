<template>
  <section class="menu">
    <div class="menu__header">Ссылки</div>
    <RouterLink :to="{ name: 'PYTHON' }" >
      <div class="menu__item">
        PYTHON
      </div>
    </RouterLink>
    <RouterLink :to="{ name: 'EGE' }" >
      <div class="menu__item">
        ЕГЭ ИНФОРМАТИКА
      </div>
    </RouterLink>
    <RouterLink :to="{ name: 'REP' }" >
      <div class="menu__item">
        РЕПЕТИТОР
      </div>
    </RouterLink>
    <RouterLink :to="{ name: 'ABOUT' }" >
      <div class="menu__item">
        ОБ АВТОРЕ
      </div>
    </RouterLink>
  </section>
</template>

<script>
export default {
  name: "MenuMain"
}
</script>

<style scoped lang="less">
.menu {

  &__header {
    font-size: 20px;
    font-weight: 700;
    padding: 10px 12px;

    background: linear-gradient(330deg, #466FFF 0%, #0CD037 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__item {
    color: #515351;
    padding: 10px 14px;
    font-weight: 700;
    font-size: 14px;
    border-top: 2px solid #efefef;
    transition: 0.2s;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: -10px;
      top: 0;
      bottom: 0;
      width: 10px;
      transition: 0.2s;
      background: linear-gradient(314deg, #009210 0%, #006614 100%);
    }

    &:hover {
      padding-left: 20px;
      color: #006614;
      &::before {
        transform: translateX(10px);
      }
    }
  }
}
</style>
