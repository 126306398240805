import modals from './modals'
import ege from './ege'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  modules: {
    modals,
    ege
  }
}
