<template>
  <PageLayout>
    <section class="p-16">
      <h4>
        Добро пожаловать!
      </h4>
      <p>Преподаватель Резников К.Г. приветствует всех на сайте, посвещенному подготовке по информатике и программированию, ЕГЭ и ОГЭ.</p>

      <h4>
        Подготовка к ЕГЭ по информатике
      </h4>
      <p>На данном сайте размещены материалы по подготовке к ЕГЭ по информатике. Разборы всех заданий во всех возможных актуальных вариантах. Представлена теоритическая основа для решения задач.</p>
      <p>Для ознакомления бесплатный доступ имеют только первое задание и соотвествующая теория.</p>

      <h4>
        Частные занятия
      </h4>
      <p>Автор сайта проводит частные и групповые занятия по подготовке к ЕГЭ и ОГЭ, если уроков недостаточно или требуется иной подход.</p>
    </section>
  </PageLayout>
</template>

<script>
import PageLayout from '../parts/PageLayout'

export default {
  name: 'HomePage',
  components: {
    PageLayout
  }
}
</script>

<style lang="less">
</style>
