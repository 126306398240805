<template>
  <section class="p-16">
    <div class="text-center">
      <div>📔</div>
      <div class="menu__header">Теория</div>
      <div class="menu__container">
        <RouterLink v-for="item in 27" :to="{ name: 'EGE_THEORY', params: { id: item } }" :key="item" >
          <div
            class="menu__item"
            :class="{
              'menu__item--unactive': !(getActivated[getCodes.theory['t' + (item)]] || getActivatedSpecialAll),
              'menu__item--active': getDone('theory' + pins[item - 1])
            }"
          >
            {{ item }}
          </div>
        </RouterLink>
      </div>
      <div>
        <button class="btn-prime menu__btn" @click="() => $root.openOpenTaskModal()">Открыть все</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "MenuEge",
  computed: {
    ...mapGetters('ege', [
      'getCodes',
      'getDone',
      'getActivated',
      'getActivatedSpecialAll'
    ]),
    pins () {
      return Object.keys(this.getCodes.theory)
    }
  }
}
</script>

<style scoped lang="less">
.menu {

  &__header {
    margin-bottom: 14px;
    font-size: 20px;
    font-weight: 700;
    color: #5c5a5a;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 8px;
  }

  &__item {
    font-weight: 400;
    background: #F3F3F3;
    text-align: center;
    line-height: 32px;
    color: #292929;
    border-radius: 4px;

    &--active {
      color: #36c736;
      background: #c1ffc1;
      font-weight: 700;
    }

    &--unactive {
      color: #f4c6c6;
      background: #ffeaea;
      font-weight: 700;
    }

    &:hover {
      background: #e0dddd;
    }
  }

  &__btn {
    margin-top: 10px;
    width: 100%;
  }
}
</style>
