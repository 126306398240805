<template>
  <PageLayout title="ЕГЭ ИНФОРМАТИКА 2024">
    <section class="p-16">
      <h4>
        Разборы заданий и теория
      </h4>

      <div class="d-flex" style="gap: 10px">
        <div class="flex-1">
          <MenuEge />
        </div>
        <div class="flex-1">
          <MenuTheory />
        </div>
      </div>
    </section>
  </PageLayout>
</template>

<script>
import PageLayout from '../parts/PageLayout'
import MenuEge from "@/components/parts/MenuEge";
import MenuTheory from "@/components/parts/MenuTheory";

export default {
  name: 'EgePage',
  components: {
    MenuEge,
    PageLayout,
    MenuTheory
  }
}
</script>

<style lang="less">
</style>
