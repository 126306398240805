<template>
  <div class="text-center page-info">
    {{ new Date().getFullYear() }} © reznikovk.ru
  </div>
</template>

<script>
export default {
  name: "PageInfo"
}
</script>

<style scoped>
.page-info {
  font-size: 12px;
  color: #7a7a7a;
  font-weight: 700;
}
</style>
