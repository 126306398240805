import Vue from 'vue'
import App from '@/components/App.vue'
import router from './router';
import Vuex from 'vuex'
import storeRoot from './store'
import { sync } from "vuex-router-sync";
import { helpModal } from "@/mixins/modals";
import { accessMixin } from "@/mixins/access";
import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/stackoverflow-light.css'

Vue.use(VueHighlightJS)
Vue.use(Vuex)
Vue.config.productionTip = false


const store = new Vuex.Store(storeRoot)
sync(store, router);

new Vue({
  render: h => h(App),
  mixins: [
    helpModal,
    accessMixin
  ],
  router,
  store
}).$mount('#app')
