<template>
  <ModalComponent>
    <div class="d-flex modal__header">
      <h4>
        Покупка доступа
      </h4>
      <div @click="() => $emit('close')" class="modal__close">
        <CloseIcon />
      </div>
    </div>
    <div class="d-flex modal__contact">
      <div style="max-width: 560px; margin-right: 16px">
        <h3 class="text-center">Прайс-лист разборов заданий и теории</h3>
        <div class="grid-price">
          <div class="grid-price__value">990 рублей</div>
          <div class="grid-price__name">Разбор одного типа задания на выбор</div>

          <div class="grid-price__value">9 990 рублей</div>
          <div class="grid-price__name">Все разборы заданий</div>
        </div>
        <br>
        <div class="grid-price">
          <div class="grid-price__value">4 990 рублей</div>
          <div class="grid-price__name">Теория по информатике</div>

          <div class="grid-price__value">7 990 рублей</div>
          <div class="grid-price__name">Теория по программированию на Python</div>

          <div class="grid-price__value">9 990 рублей</div>
          <div class="grid-price__name">Вся теория (информатика + программирование на Python)</div>
        </div>
        <br>
        <div class="grid-price">
          <div class="grid-price__value">16 990 рублей</div>
          <div class="grid-price__name">Все разборы и теория (полный доступ)</div>
        </div>
        <br>
        <div class="text-center">
          Для покупки свяжитесь с администратором <br> любым удобным для вас способом ➡️
        </div>
      </div>

      <MenuContact style="width: 260px" />
    </div>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/parts/Modal";
import MenuContact from "@/components/parts/MenuContact";
import CloseIcon from "@/components/icons/Close";

export default {
  name: "OpenTaskModal",
  components: {
    ModalComponent,
    CloseIcon,
    MenuContact
  },
  props: {
    params: Object
  },
  methods: {
    click (btn) {
      if (btn.click) {
        this.clickHandler(btn.click)
      }

      if (btn.afterClick) {
        this.clickHandler(btn.afterClick)
      }
    },
    clickHandler (click) {
      if (typeof click === 'string') {
        if (click === 'emitClose') {
          return this.$emit('close')
        }
      }

      return click()
    }
  }
}
</script>

<style scoped lang="less">
.modal__contact {
  justify-content: space-between;
  padding: 0 16px 16px;
}

.grid-price {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-column-gap: 10px;

  &__name {

  }

  &__value {
    text-align: right;
    font-weight: bold;
  }
}
</style>
