<template>
  <div class="product" @click="() => $root.openRepModal()">
    <div v-if="title" class="product__title">{{ title }}</div>
    <div v-if="time" class="product__time">{{ time }}</div>
    <div v-if="price" class="product__price">{{ price }} рублей</div>
    <div v-if="sub" class="product__sub">{{ sub }}</div>
  </div>
</template>

<script>
export default {
  name: "Product",
  props: {
    title: {
      default: '',
      type: String
    },
    price: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    },
    sub: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="less">
.product {
  background: #eef3f7;
  text-align: center;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background: #e1e7ec;
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    color: #576168;
  }

  &__time {
    font-size: 12px;
  }

  &__price {
    margin-top: 8px;
    color: #009029;
  }

  &__sub {
    font-size: 10px;
    margin-top: 6px;
    color: #7ea96a;
  }
}
</style>
