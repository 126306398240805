import Vue from 'vue';
import VueRouter from 'vue-router';
import { RouteNames } from './routes';
import HomePage from '../components/pages/Home.vue';
import EgePage from '../components/pages/Ege.vue';
import TaskPage from '../components/pages/Task.vue';
import TheoryPage from '../components/pages/Theory.vue';
import AboutPage from '../components/pages/About.vue';
import RepPage from '../components/pages/Rep.vue';
import PythonPage from '../components/pages/Python.vue';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: RouteNames.HOME,
        component: HomePage
    },
    {
        path: '/ege',
        name: RouteNames.EGE,
        component: EgePage
    },
    {
        path: '/task/:id',
        name: RouteNames.EGE_TASK,
        component: TaskPage
    },
    {
        path: '/theory/:id',
        name: RouteNames.EGE_THEORY,
        component: TheoryPage
    },
    {
        path: '/rep',
        name: RouteNames.REP,
        component: RepPage
    },
    {
        path: '/about',
        name: RouteNames.ABOUT,
        component: AboutPage
    },
    {
        path: '/python',
        name: RouteNames.PYTHON,
        component: PythonPage
    },
];
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});
export default router;
