<template>
  <PageLayout title="ЕГЭ Информатика">
    <section class="p-16">
      <Component v-if="theory" :is="theory" />
      <div v-else>
        <TaskContainer />
      </div>
    </section>

    <template slot="sidebar">
      <MenuTheory />
    </template>
  </PageLayout>
</template>

<script>
import PageLayout from '../parts/PageLayout'
import { helpModal } from "@/mixins/modals";
import MenuTheory from "@/components/parts/MenuTheory";
import TaskContainer from "@/components/parts/TaskContainer";

const getTheory = (id) => import('../theory/' + id)

export default {
  name: 'TheoryPage',
  mixins: [helpModal],
  components: {
    PageLayout,
    MenuTheory,
    TaskContainer
  },
  data () {
    return {
      theory: null
    }
  },
  computed: {
    theoryNumber () {
      return this.$route.params.id
    }
  },
  mounted () {
    this.loadTheory()
  },
  watch: {
    theoryNumber: {
      handler () {
        this.loadTheory()
      }
    }
  },
  methods: {
    loadTheory () {
      getTheory(this.theoryNumber)
        .then((v) => {
          this.theory = v.default
        })
        .catch(() => {
          this.theory = null
        })
    }
  }
}
</script>

<style lang="less">
.grid {
  display: grid;
  grid-gap: 24px;

  &--main {
    grid-template-columns: 1fr 240px;
  }
}

.contacts {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}
</style>
