<template>
  <div>
    <RouterView />
    <ModalContainer />
  </div>
</template>

<script>
import ModalContainer from "@/components/parts/ModalContainer";

export default {
  components: {
    ModalContainer
  }
}
</script>

<style lang="less">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  padding: 0;
  background-color: #efefef;
  font-family: 'Montserrat', sans-serif;
}

a {
  text-decoration: none;
}

section {
  background-color: @cBaseOne;
  margin-bottom: 20px;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0px 2px 11px 0px #e2e2e2;

  &:last-child {
    margin-bottom: 0;
  }
}

h1, h2, h3, h4, h5 {
  font-family: @ffOne;
  color: @cBaseThree;
  margin: 0;
  margin-bottom: 14px;
}

h1 {
  font-size: 36px;
  margin-bottom: 16px;
}

h2 {
  font-size: 32px;
  margin-bottom: 14px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 12px;
}

p {
  font-size: 14px;
  color: #222222;
}

.p-16 {
  padding: 16px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.flex-1 {
  flex: 1;
}

.d-flex {
  display: flex;

  &--v-center {
    align-items: center;
  }

  &-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.gap-12 {
  gap: 12px
}

.no-margin {
  margin: 0;
}

.mt-20 {
  margin-top: 20px;
}

.mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mb-12 {
  margin-bottom: 12px;
}

.text-center {
  text-align: center;
}

.btn-prime {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 6px;
  border: 1px solid #c2e7b0;
  background: #67c23a;
  color: #ffffff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;

  &:hover {
    background: #56a82d;
    color: white;
    border-color: #67c23a;
  }

  &.disabled {
    cursor: not-allowed;
    background: #7c7878;
    border: 1px solid #7c7878;

    &:hover {
      background: #7c7878;
      color: white;
      border: 1px solid #7c7878;
    }
  }
}

.text-center {
  text-align: center;
}

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 6px;
  border: 1px solid #c2e7b0;
  background: #f0f9eb;
  color: #67c23a;
  border-radius: 5px;
  font-size: 16px;

  &--b {
    border: 1px solid #b0c8e7;
    background: #ebf6f9;
    color: #3aaec2;
  }

  &--y {
    border: 1px solid #e7e0b0;
    background: #f9f5eb;
    color: #c2ab3a;
  }
}

.my-scroll {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-width: auto;

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.2);
    transition: 0.25ms;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
</style>
