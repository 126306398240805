<template>
  <div class="form-control">
    <div
      v-if="label"
      class="form-label"
      :class="[ labelUp ? 'active' : '', status ]"
      @click="setForceLabel">{{ label}}
    </div>
    <div class="form-input-wrapper">
      <input
        class="form-input"
        :class="[ status ]"
        :disabled="disabled"
        :value="value"
        :type="type"
        @input="emitVal"
        @focus="setForceLabel(true)"
        @blur="setForceLabel(false)"
        ref="input"
      >
      <div
        class="icon"
        :class="status">
        <!--                <font-awesome-icon v-if="getIconStatus" :icon="getIconStatus" />-->
      </div>
    </div>
    <span class="dialog" v-if="message">{{ message }}</span>
  </div>
</template>

<script>
// import { library } from '@fortawesome/fontawesome-svg-core';
// import {
//     faCheck,
//     faExclamationCircle
// } from '@fortawesome/free-solid-svg-icons';
//
// library.add(faCheck);
// library.add(faExclamationCircle);

export default {
  name: "CustomInput",
  data() {
    return {
      forceLabel: false
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  computed: {
    getIconStatus: function() {
      if (this.status === 'success') {
        return 'check'
      } else if (this.status === 'error') {
        return 'exclamation-circle'
      } else {
        return false;
      }
    },
    labelUp: function() {
      return (this.value && this.value.length > 0) || this.forceLabel;
    }
  },
  methods: {
    emitVal: function(e) {
      this.$emit('input', e.target.value);
    },
    setForceLabel: function(v = true) {
      if (v) this.$refs.input.focus();
      this.forceLabel = v;
    }
  }
}
</script>

<style scoped lang="less">
.form-control {
  @padding-form: 12px 14px 10px;
  position: relative;

  .form-label {
    position: absolute;
    padding: 0 14px;
    font-family: @ffOne, sans-serif;
    font-size: 16px;
    color: #C2C2C2;
    transition: 0.2s ease-in-out;
    top: 10px;
    z-index: 1;
    user-select: none;
    cursor: text;

    &.active {
      font-size: 14px;
      top: -20px;
      color: #000000;
    }

    &.success {
      color: #75dc75;
    }

    &.error {
      color: #cc524a;
    }
  }

  .form-input {
    width: 100%;
    color: #000000;
    padding: @padding-form;
    box-sizing: border-box;
    transition: 0.2s;
    font-family: @ffOne, sans-serif;
    font-size: 14px;
    background: white;
    border-radius: 2px;
    outline: none;
    border: 1px solid @cBaseTwo;
    border-bottom: 1px solid @cBaseTwo;

    &:focus {
      border: 1px solid #000000;
    }

    &-wrapper {
      position: relative;
      margin-top: 20px;
    }

    &::placeholder {
      color: #bfbfbf;
    }

    &.success {
      border-bottom: 1px solid #75dc75;

      &:focus {
        border: 1px solid #75dc75;
        border-bottom: 1px solid #75dc75;
      }
    }

    &.error {
      border-bottom: 1px solid #cc524a;

      &:focus {
        border: 1px solid #cc524a;
        border-bottom: 1px solid #cc524a;
      }
    }

    &[disabled] {
      border-bottom: 1px solid #777;
    }
  }

  & .icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: @padding-form;

    &.success {
      color: #75dc75;
    }

    &.error {
      color: #cc524a;
    }
  }

  & .dialog {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    padding: 5px 10px;
    color: #cc524a;
    font-weight: bold;
  }
}
</style>
