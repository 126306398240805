export var RouteNames;
(function (RouteNames) {
    RouteNames["HOME"] = "HOME";
    RouteNames["ABOUT"] = "ABOUT";
    RouteNames["EGE"] = "EGE";
    RouteNames["EGE_TASK"] = "EGE_TASK";
    RouteNames["EGE_THEORY"] = "EGE_THEORY";
    RouteNames["REP"] = "REP";
    RouteNames["PYTHON"] = "PYTHON";
})(RouteNames || (RouteNames = {}));
